import { type LucideProps } from "lucide-react"

const TonKeeper = (props: LucideProps) => (
<svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M16 13L0 7L16 1L32 7L16 13Z" fill="#45AEF5" />
  <path opacity="0.6" d="M16 13L32 7L16 31V13Z" fill="#45AEF5" />
  <path opacity="0.8" d="M16 13L0 7L16 31V13Z" fill="#45AEF5" />
</svg>
)

export default TonKeeper
